<template>
  <div class="whole">
    <div class="nav">
      <div class="logo" @click="ongoHome"></div>
      <div class="select-search">
        <!-- <div class="select">
          <el-select v-model="keyWord" filterable placeholder="关键字">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="search" v-if="searchkey == 1">
          <el-input
            class="serarch-input"
            v-model="search"
            placeholder="所搜即所得"
            @keyup.enter.native="onSearch"
          ></el-input>
          <div class="icon el-icon-search" @click="onSearch"></div>
        </div>
        <div class="picSearch" v-else @click="onPicSearch">
          以图搜图
          <span class="el-icon-search"></span>
        </div>
      </div>
      <div class="login">
        <div class="login-btn">
          <div class="register" v-if="!$rtm.store.loginStatus">
            <a href="register.html">注册</a>
          </div>
          <div class="login-item" v-if="!$rtm.store.loginStatus">
            <a href="login.html">登录</a>
          </div>
          <div class="logined" v-if="$rtm.store.loginStatus">
            <div class="login-up" v-if="!roleTypeShow">
              <div class="info">
                <a href="myInfo.html">个人信息</a>
              </div>
              <div class="uppost">
                <div class="uphover">稿件上传</div>
                <div class="upDrow">
                  <div
                    class="uploadPic red-nav-button"
                    @click="onUploadPic"
                    v-if="userInfo.upPicStores.length != 0"
                  >
                    上传照片 <span class="el-icon-upload2"></span>
                  </div>
                  <div
                    class="uploadVideo red-nav-button"
                    @click="onUploadVideo"
                    v-if="userInfo.upVideoStores != 0"
                  >
                    上传视频 <span class="el-icon-upload2"></span>
                  </div>
                </div>
              </div>
              <div class="mypost">
                <a href="myPost.html">我的图库</a>
              </div>
            </div>
            <div class="login-down" v-if="roleTypeShow">
              <div class="info">
                <a href="myInfo.html">个人信息</a>
              </div>
              <div @click="onAlreadyBuy">已购买</div>
              <div @click="onShopCar">购物车</div>
              <div @click="onOrder">我的订单</div>
            </div>

            <div class="logout" @click="onOutLogin">退出</div>
          </div>
        </div>
      </div>
    </div>

    <div class="list">
      <div class="nav-list">
        <div
          class="nav-detail"
          v-for="(k, navIndex) in navList"
          :key="k + 'nav_' + navIndex"
          :class="{ active: clickIndex == navIndex }"
        >
          <a :href="k.url">{{ k.name }}</a>
          <div class="nav-detail-subs">
            <div
              class="nav-detail-seq"
              v-for="(p, pindex) in k.subs"
              :key="p + 'navseq_' + pindex"
            >
              <a v-if="p.dis" @click="onNoOpen">{{ p.name }}</a>
              <a v-else :href="p.url">{{ p.name }}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="tbaber"></div> -->
    </div>

    <!-- 二维码进小程序 -->
    <div class="wxQR">
      <div class="wx-out">
        <div class="wx-text">手机传稿</div>
        <div class="wx-img">
          <div class="wxqr"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";
export default {
  name: "",
  components: {},
  data() {
    return {
      contents: [], // 美美吴江的数据
      roleTypeShow: false, // 用户的角色类型
      navList: [
        { name: "首页", url: "index.html" },
        {
          name: "精品图库",
          url: "catalog.html?title=精品图库",
          subs: [
            { name: "现代吴江", id: 120, url: "/fixTopic.html?id=120" },
            { name: "生态吴江", id: 11, url: "/fixTopic.html?id=11" },
            { name: "记忆吴江", id: 12, url: "/fixTopic.html?id=12" },
            { name: "人文吴江", id: 13, url: "/fixTopic.html?id=13" },
          ],
        },
        {
          name: "今日读图",
          url: "catalog.html?title=今日读图",
          subs: [
            { name: "时政速览", id: 3, url: "/fixTopic.html?id=3" },
            { name: "经济发展", id: 6, url: "/fixTopic.html?id=6" },
            { name: "社会民生", id: 4, url: "/fixTopic.html?id=4" },
            { name: "乡村振兴", id: 119, url: "/fixTopic.html?id=119" },
            { name: "文化强区", id: 5, url: "/fixTopic.html?id=5" },
          ],
        },
        {
          name: "区镇图库",
          url: "local.html",
          subs: [
            {
              name: "吴江开发区",
              id: 6611,
              url: "local.html?id=6611&name=吴江开发区",
            },
            {
              name: "汾湖高新区（黎里镇)",
              id: 6609,
              url: "local.html?id=6609&name=汾湖高新区（黎里镇)",
            },
            {
              name: "吴江高新区（盛泽镇)",
              id: 6610,
              url: "local.html?id=6610&name=吴江高新区（盛泽镇)",
            },
            {
              name: "东太湖度假区",
              id: 6612,
              url: "local.html?id=6612&name=东太湖度假区",
            },
            { name: "七都镇", id: 6606, url: "local.html?id=6606&name=七都镇" },
            { name: "桃源镇", id: 6608, url: "local.html?id=6608&name=桃源镇" },
            { name: "震泽镇", id: 6607, url: "local.html?id=6607&name=震泽镇" },
            { name: "平望镇", id: 6604, url: "local.html?id=6604&name=平望镇" },
          ],
        },
        {
          name: "视频",
          // url: "catalog.html?title=视频",
          url: "/fixTopic.html?id=17",
          subs: [
            // { name: "视频", id: 15, url: "/fixTopic.html?id=15" },
            { name: "精彩视频", id: 17, url: "/fixTopic.html?id=17" },
          ],
        },
        { name: "签约摄影师", url: "userShow.html" },
        // { name: "新闻", url: "fixTopic.html?id=16" },
      ], // 导航栏的数组
      clickIndex: -1,
      searchkey: 1,
      search: "",
      keyWord: 1,
      options: [
        {
          value: 1,
          label: "关键词",
        },
        {
          value: 2,
          label: "图片",
        },
      ],
      loginStatus: false, // 是否登录的状态
      userInfo: {}, // 登录用户信息
    };
  },
  created() {
    this.reload();
  },
  mounted() {},
  methods: {
    reload() {
      var thiz = this;
      console.log("633", thiz.$rtm.store.loginStatus);
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          if (data.roleType == "DOWNLOADER") {
            thiz.roleTypeShow = true;
          } else if (data.roleType == "UPLOADER") {
            thiz.roleTypeShow = false;
          }

          thiz.$rtm.store.loginStatus = true;
          thiz.userInfo = data;
        })
        .catch(() => {
          thiz.$rtm.loginStatus = false;
        });
      RD.index()
        .visit()
        .then((data) => {
          thiz.$rtm.store.visitCount = data;
        });
      RD.activityTopic()
        .forShow()
        .then((data) => {
          data.forEach((k) => {
            k.imgURL = RD.activityTopic().id(k.id).headPic_URL(0);
          });
          thiz.$rtm.store.activityList = data;
        });
    },
    // 上传图片
    onUploadPic() {
      if (this.$rtm.store.loginStatus) {
        this.$router.push({
          path: "/upPost.html",
        });
      } else {
        this.$message("请先登录账户再上传");
      }
    },
    // 上传视频
    onUploadVideo() {
      if (this.$rtm.store.loginStatus) {
        this.$router.push({
          path: "/uploadVideo.html",
        });
      } else {
        this.$message("请先登录账户再上传");
      }
    },
    onNoOpen() {
      this.$notify({
        title: "提示",
        message: "当前模块未开放",
      });
    },
    // 退出登录
    onOutLogin() {
      this.$confirm("确定要退出嘛?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          RD.user()
            .logout()
            .then(() => {
              this.$alert("您已成功退出登录", "成功退出", {
                confirmButtonText: "确定",
                type: "success",
                callback: (action) => {
                  this.$router.push("/index.html");
                  this.$rtm.store.loginStatus = false;
                },
              });
              this.loginStatus = false;
            });
        })
        .catch(() => {});
    },
    // 前往搜索页
    onSearch() {
      this.$router.push({
        path: "/search.html",
        query: { keyWord: this.search },
      });
    },
    ongoHome() {
      this.$router.push("/index.html");
    },
    // 已购买
    onAlreadyBuy() {
      this.$router.push({
        path: "/alreadyBuy.html",
      });
    },
    onShopCar() {
      this.$router.push({
        path: "/shopCar.html",
      });
    },
    onOrder() {
      this.$router.push({
        path: "/myOrder.html",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.whole {
  width: 100%;
  @media all and (max-width: 800px) {
    .nav {
      height: 8rem;
      width: 750px;
      margin: 0 auto 1rem;
      display: flex;
      position: relative;
      .logo {
        width: 150px;
        height: 7rem;
        background: no-repeat url(../assets/logo.png);
        background-size: contain;
        margin-top: 10px;
      }

      .select-search {
        width: 300px;
        display: flex;
        height: 4rem;
        margin-top: 2rem;
        margin-left: 0.5rem;
        .select {
          width: 100px;
        }
        .search {
          width: 200px;
          display: flex;
          flex-shrink: 0;
          position: relative;
          .el-input {
            width: 150px;
            margin: 0rem 0.3rem;
          }
          .serarch-input {
            outline: none !important;
            border: 0rem !important;
            padding-left: 1rem;
          }
          .icon {
            width: 5rem;
            font-size: 2rem;
            padding-top: 0.5rem;
            border-radius: 0.5rem;
            height: 3rem;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 50px;
          }
          .height {
            width: 7rem;
            height: 4rem;
            line-height: 4rem;
            font-size: 1.2rem;
            cursor: pointer;
          }
        }
        .picSearch {
          width: 13.6rem;
          margin-left: 2rem;
          border-radius: 0.5rem;
          height: 3.8rem;
          line-height: 3.8rem;
          font-size: 1.2rem;
          border: 0.1rem solid #ccc;
          cursor: pointer;
          position: relative;
          background-color: #dfdfdf;
          span {
            position: absolute;
            top: 0.7rem;
            right: 1rem;
            font-size: 2rem;
          }
        }
      }

      .nav-left {
        height: 100%;
        width: 40%;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        color: #fff;
        margin-top: 8px;
        .text-pic {
          margin-left: 40px;
        }
      }
      .login {
        position: absolute;
        top: 1rem;
        right: 0px;
        width: 300px;
        height: 40px;
        line-height: 40px;
        .login-btn {
          display: flex;
          justify-content: flex-end;
          flex-shrink: 0;
          flex-wrap: wrap;
          height: 100%;
          width: 100%;
          .register {
            width: 5rem;
            border: 1px solid #ccc;
            height: 30px;
            line-height: 30px;
            border-radius: 5px;
            font-size: 14px;
            color: #000;
            padding: 0px 8px;
            margin-top: 1rem;
            a {
              color: #000;
            }
          }
          .login-item {
            width: 5rem;
            border: 1px solid #ccc;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            padding: 0px 8px;
            color: #000;
            margin-left: 8px;
            border-radius: 5px;
            margin-top: 1rem;
            a {
              width: 100%;
              height: 100%;
              color: #000;
            }
          }
          .logined {
            color: #000;
            display: flex;
            line-height: 30px;
            margin-top: 1rem;
            width: 100%;
            .login-up {
              border-left: 2px solid #000;
              font-size: 16px;
              width: 100%;
              height: 30px;
              padding: 0 5px;
              line-height: 30px;
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
                color: #000;
              }
            }
            .welcome {
              width: auto;
              border: 0;
            }
            .logout {
              border-right: 2px solid #595758;
              font-size: 16px;
              width: 7rem;
              height: 20px;
              padding: 0 5px;
              cursor: pointer;
            }
            .uppost {
              border: 0;
              .uphover {
                font-size: 16px;
                text-align: center;
                cursor: pointer;
              }
              .upDrow {
                height: auto;
                display: none;
                width: auto;
                min-width: 7rem;
                margin: 0 auto;
                border-radius: 0.48rem;
                border: 1px solid red;
                color: #e63439;
                background-color: white;
                z-index: 1001;
                border: 0;
                .uploadPic {
                  height: 2.5rem;
                  line-height: 2.5rem;
                  font-size: 1.3rem;
                  cursor: pointer;
                  text-align: center;
                  border: 0;
                }
                .uploadVideo {
                  height: 2.5rem;
                  line-height: 2.5rem;
                  font-size: 1.3rem;
                  text-align: center;
                  margin-top: 0.48rem;
                  cursor: pointer;
                  border: 0;
                }
              }
            }
            .uppost:hover {
              .upDrow {
                display: inline-block;
              }
            }
          }
          .register:hover {
            background-color: #f96a6e;
            a {
              color: #fff;
            }
          }
          .login-item:hover {
            background-color: #f96a6e;
            a {
              color: #fff;
            }
          }
        }
      }
      .nav-search {
        display: flex;
        margin-top: 18px;
        width: 50rem;
        position: absolute;
        top: 55rem;
        left: 40rem;
        z-index: 10;
        .search {
          width: 50rem;
          height: 4rem;
          overflow: hidden;
          margin-left: 10px;
          position: relative;
          ::v-deep .el-input {
            height: 30px;
            .el-input__inner {
              height: 4rem;
              border-radius: 10px;
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
          .icon {
            position: absolute;
            right: 6px;
            top: 7px;
            width: 20px;
            height: 20px;
            // background: no-repeat url(../assets/search_icon.png);
            background-size: cover;
            cursor: pointer;
          }
          .search-item {
            position: absolute;
            right: 0px;
            top: 0px;
            // background-color: #f36c72;
            color: #fff;
            font-size: 1.8rem;
            width: 10rem;
            height: 4rem;
            line-height: 4rem;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .list {
      width: 750px;
      margin: 0 auto;
      background-color: #fff;
      display: flex;
      font-size: 16px;
      .nav-list {
        width: 750px;
        // margin: 0 auto;
        height: 8rem;
        line-height: 8rem;
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        justify-content: space-between;
        .nav-detail {
          font-size: 16px;
          cursor: pointer;
          position: relative;
          text-align: left;
          height: 100%;
          width: 100px;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: black;
            text-decoration: none;
            text-align: center;
            // padding: 0px 0.8rem;
          }
          .nav-detail-subs {
            position: absolute;
            top: 7.3rem;
            left: 0px;
            width: 120px;
            height: auto;
            z-index: 99;
            // overflow: hidden;
            display: none;
            background: rgba(255, 255, 255, 0.97);
            border-radius: 4px 4px 4px 4px;
            // border: 1px solid rgba(153, 153, 153, 0.3);
            box-shadow: 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.3);
            transition: 500ms all ease-in-out;
            .nav-detail-seq {
              font-size: 1.6rem;
              height: 0px;
              color: #000;
              transition: 500ms all ease-in-out;
              cursor: pointer;
              text-align: center;
              a {
                color: #000;
                text-decoration: none;
                height: 3rem;
                font-size: 16px;
                font-weight: 400;
              }
            }
            .nav-detail-seq:hover {
              color: #fff;
              background: #d7140c;
              a {
                color: #fff;
              }
            }
          }
        }
        .nav-detail:hover {
          // border-bottom: 2px solid red;
          a {
            color: red;
          }
          .nav-detail-subs {
            display: block;
          }
          .nav-detail-seq {
            height: 2.5rem;
            line-height: 2.5rem;
            margin: 0.8rem 0px 0px 0px;
            a {
              height: 2.5rem;
              // color: #fff;
            }
          }
        }
        .nav-detail.active {
          color: red;
        }
      }
      .tbaber {
        width: 20rem;
        height: 6.1rem;
        margin-top: 2rem;
        margin-left: 2rem;
        background: no-repeat url(../assets/logo2.png);
        background-size: contain;
      }
    }
  }
  @media all and (min-width: 801px) {
    .nav {
      height: 8rem;
      width: 120rem;
      margin: 0 auto 1rem;
      display: flex;
      position: relative;
      .logo {
        width: 20rem;
        height: 7rem;
        background: no-repeat url(../assets/logo.png);
        background-size: contain;
        margin-top: 15px;
        cursor: pointer;
      }

      .select-search {
        width: 25rem;
        display: flex;
        height: 4rem;
        margin-top: 3rem;
        margin-left: 0.5rem;
        .select {
          width: 8.9rem;
        }
        .search {
          width: 16rem;
          display: flex;
          flex-shrink: 0;
          position: relative;
          .el-input {
            width: 16rem;
            margin: 0rem 0.3rem;
          }
          .serarch-input {
            outline: none !important;
            border: 0rem !important;
            padding-left: 1rem;
          }
          .icon {
            width: 5rem;
            font-size: 2rem;
            padding-top: 0.5rem;
            border-radius: 0.5rem;
            height: 3rem;
            cursor: pointer;
            position: absolute;
            top: 0.4rem;
            right: -0.2rem;
          }
          .height {
            width: 7rem;
            height: 4rem;
            line-height: 4rem;
            font-size: 1.2rem;
            cursor: pointer;
          }
        }
        .picSearch {
          width: 13.6rem;
          margin-left: 2rem;
          border-radius: 0.5rem;
          height: 3.8rem;
          line-height: 3.8rem;
          font-size: 1.2rem;
          border: 0.1rem solid #ccc;
          cursor: pointer;
          position: relative;
          background-color: #dfdfdf;
          span {
            position: absolute;
            top: 0.7rem;
            right: 1rem;
            font-size: 2rem;
          }
        }
      }

      .nav-left {
        height: 100%;
        width: 40%;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        color: #fff;
        margin-top: 8px;
        .text-pic {
          margin-left: 40px;
        }
      }
      .login {
        position: absolute;
        top: 0.5rem;
        right: 0px;
        width: 30rem;
        height: 4rem;
        line-height: 4rem;
        .login-btn {
          display: flex;
          justify-content: flex-end;
          flex-shrink: 0;
          flex-wrap: wrap;
          height: 100%;
          margin-top: 2rem;
          .register {
            width: 5rem;
            border: 1px solid #ccc;
            height: 3rem;
            line-height: 3rem;
            border-radius: 5px;
            font-size: 14px;
            color: #000;
            padding: 0px 8px;
            margin-top: 1rem;
            a {
              color: #000;
            }
          }
          .login-item {
            width: 5rem;
            border: 1px solid #ccc;
            height: 3rem;
            line-height: 3rem;
            font-size: 14px;
            padding: 0px 8px;
            color: #000;
            margin-left: 8px;
            border-radius: 5px;
            margin-top: 1rem;
            a {
              width: 100%;
              height: 100%;
              display: inline-block;
              color: #000;
            }
          }
          .logined {
            color: #000;
            display: flex;
            line-height: 30px;
            .login-up {
              display: flex;
              div {
                border-right: 2px solid #595758;
                font-size: 16px;
                width: 7rem;
                height: 20px;
                padding: 0 5px;
                cursor: pointer;
                line-height: 2rem;
                margin-top: 4rem;
                a {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  // color: #595758;
                  text-decoration: none;
                }
              }
              .uppost {
                border: 0;
                .uphover {
                  line-height: 2rem;
                  font-size: 1.6rem;
                  text-align: center;
                  cursor: pointer;
                  margin-top: 0px;
                }
                .upDrow {
                  height: auto;
                  display: none;
                  width: auto;
                  min-width: 7rem;
                  margin: 0 auto;
                  border-radius: 0.48rem;
                  border: 1px solid red;
                  color: #e63439;
                  background-color: white;
                  z-index: 1001;
                  border: 0;
                  .uploadPic {
                    height: 2.5rem;
                    line-height: 2.5rem;
                    font-size: 1.3rem;
                    cursor: pointer;
                    text-align: center;
                    border: 0;
                    margin-top: 0px;
                  }
                  .uploadVideo {
                    height: 2.5rem;
                    line-height: 2.5rem;
                    font-size: 1.3rem;
                    text-align: center;
                    margin-top: 0.48rem;
                    cursor: pointer;
                    border: 0;
                    margin-top: 0px;
                  }
                }
              }
              .uppost:hover {
                .upDrow {
                  display: inline-block;
                }
              }
            }
            .login-down {
              display: flex;
              div {
                border-right: 2px solid #595758;
                font-size: 16px;
                width: 7rem;
                height: 20px;
                padding: 0 5px;
                cursor: pointer;
                line-height: 2rem;
                margin-top: 4rem;
                a {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  color: #595758;
                  text-decoration: none;
                }
              }
            }
            .logout {
              border-right: 2px solid #595758;
              font-size: 16px;
              width: 7rem;
              height: 20px;
              padding: 0 5px;
              cursor: pointer;
              line-height: 2rem;
              margin-top: 4rem;
            }
          }
          .register:hover {
            background-color: #f96a6e;
            a {
              color: #fff;
            }
          }
          .login-item:hover {
            background-color: #f96a6e;
            a {
              color: #fff;
            }
          }
        }
      }
      .nav-search {
        display: flex;
        margin-top: 18px;
        width: 50rem;
        position: absolute;
        top: 55rem;
        left: 40rem;
        z-index: 10;
        .search {
          width: 50rem;
          height: 4rem;
          overflow: hidden;
          margin-left: 10px;
          position: relative;
          ::v-deep .el-input {
            height: 30px;
            .el-input__inner {
              height: 4rem;
              border-radius: 10px;
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
          .icon {
            position: absolute;
            right: 6px;
            top: 7px;
            width: 20px;
            height: 20px;
            // background: no-repeat url(../assets/search_icon.png);
            background-size: cover;
            cursor: pointer;
          }
          .search-item {
            position: absolute;
            right: 0px;
            top: 0px;
            // background-color: #f36c72;
            color: #fff;
            font-size: 1.8rem;
            width: 10rem;
            height: 4rem;
            line-height: 4rem;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .list {
      width: 120rem;
      margin: 0 auto;
      background-color: #fff;
      display: flex;
      .nav-list {
        width: 120rem;
        // margin: 0 auto;
        height: 8rem;
        line-height: 8rem;
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        justify-content: space-between;
        .nav-detail {
          font-size: 1.8rem;
          cursor: pointer;
          position: relative;
          text-align: left;
          height: 100%;
          width: 10rem;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: black;
            text-decoration: none;
            text-align: center;
            // padding: 0px 0.8rem;
          }
          .nav-detail-subs {
            position: absolute;
            top: 7.3rem;
            left: 0px;
            width: 151px;
            height: auto;
            z-index: 99;
            // overflow: hidden;
            display: none;
            background: rgba(255, 255, 255, 0.97);
            border-radius: 4px 4px 4px 4px;
            // border: 1px solid rgba(153, 153, 153, 0.3);
            box-shadow: 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.3);
            transition: 500ms all ease-in-out;
            .nav-detail-seq {
              font-size: 1.6rem;
              height: 0px;
              color: #000;
              transition: 500ms all ease-in-out;
              cursor: pointer;
              text-align: center;
              a {
                color: #000;
                text-decoration: none;
                height: 3rem;
                font-size: 16px;
                font-weight: 400;
              }
            }
            .nav-detail-seq:hover {
              color: #fff;
              background: #d7140c;
              a {
                color: #fff;
              }
            }
          }
        }
        .nav-detail:hover {
          // border-bottom: 2px solid red;
          a {
            color: red;
          }
          .nav-detail-subs {
            display: block;
          }
          .nav-detail-seq {
            height: 2.5rem;
            line-height: 2.5rem;
            margin: 0.8rem 0px 0px 0px;
            a {
              height: 2.5rem;
              // color: #fff;
            }
          }
        }
        .nav-detail.active {
          color: red;
        }
      }
      .tbaber {
        width: 20rem;
        height: 6.1rem;
        margin-top: 2rem;
        margin-left: 2rem;
        background: no-repeat url(../assets/logo2.png);
        background-size: contain;
      }
    }
  }

  .wxQR {
    position: fixed;
    z-index: 10;
    top: 32rem;
    right: -19rem;
    width: 22rem;
    height: 18.8rem;
    transition: all 200ms ease-in-out;
    transition-delay: 1s;
    border: 0.3rem solid rgb(133, 131, 131);
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    overflow: hidden;
    .wx-out {
      display: flex;
      height: 100%;
      .wx-text {
        width: 3rem;
        height: 100%;
        writing-mode: tb-rl;
        text-align: center;
        font-size: 1.5rem;
        background: rgb(102, 100, 100);
        padding-right: 0.5rem;
        color: #fff;
        text-shadow: 0rem 0rem 0.5rem #000;
      }
      .wx-img {
        width: 20.5rem;
        height: 100%;
        .wxqr {
          width: 100%;
          height: 100%;
          background: no-repeat url(../assets/wxQR.jpg);
          background-size: contain;
        }
      }
    }
  }
  .wxQR:hover {
    right: 0rem;
    transition-delay: 0s;
    z-index: 9;
  }
}
</style>